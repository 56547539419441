import css from '@emotion/css';
import { lighten } from 'polished';
import styled from '@emotion/styled';

import colors from 'Utils/theme';
import { minTablet, max1025, min1280, min1440 } from 'Utils/variables';

import Link from 'Components/link';

import { responsiveFont } from 'Styles/helpers';

export const Button = styled.button`
  position: ${(props) => (props.includeZipInput ? 'relative' : 'absolute')};
  display: flex;
  align-items: center;
  justify-content: center;
  right: ${(props) => (props.inCta ? '3px' : '6px')};
  top: ${(props) => (props.inCta ? '3px' : '6px')};
  line-height: ${(props) => (props.inCta ? '44px' : '38px')};
  height: ${(props) => (props.inCta ? '44px' : '38px')};
  width: ${(props) => (props.inCta ? '44px' : '70px')};
  max-width: ${(props) => (props.inCta ? '120px' : '80px')};
  margin: 0;
  background-color: ${(props) => (props.disabled ? colors.inHome.medGrey : colors.walmart.yellow)};
  color: ${(props) => (props.disabled ? colors.inHome.darkGrey : colors.walmart.blue)};

  border-radius: ${(props) => (props.inCta ? '22px' : '4px')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: width 300ms ease-in-out;

  @media (min-width: ${max1025}) {
    height: ${(props) => (props.inCta ? '64px' : '38px')};
    width: ${(props) => (props.inCta ? '64px' : '70px')};
    border-radius: ${(props) => (props.inCta ? '32px' : '4px')};
  }

  &:hover,
  &:focus {
    background-color: ${(props) => (props.disabled ? colors.inHome.medGrey : lighten(0.1, colors.walmart.yellow))};
  }

  ${(props) =>
    props.inCta &&
    css`
      svg {
        width: 18px;
        height: auto;

        @media (min-width: ${max1025}) {
          width: 22px;
          height: auto;
        }
      }
    `};

  ${(props) =>
    props.compactButton &&
    css`
      width: 25px;

      svg {
        width: 14px;
        height: auto;
      }

      @media (min-width: ${max1025}) {
        width: 25px;
      }
    `}
`;

export const Input = styled.input`
  flex-grow: 1;
  line-height: 50px;
  height: 50px;
  padding: ${(props) => (props.inCta ? '0 8px' : '0 36px 0 6px')};
  width: 100%;

  color: ${colors.walmart.blue};
  font-size: ${(props) => responsiveFont({ sizes: props.inCta ? '18,18' : '14,16' })};

  border: 1px solid ${colors.inHome.lightGrey};
  border-radius: ${(props) => (props.inCta ? '100px' : '4px')};

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.inCta &&
    css`
      background-color: ${colors.white};
      padding-left: 20px;
    `}

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${colors.inHome.darkGrey};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${colors.inHome.darkGrey};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${colors.inHome.darkGrey};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${colors.inHome.darkGrey};
  }

  @media (min-width: ${max1025}) {
    height: ${(props) => (props.inCta ? 70 : 50)}px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Field = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${(props) => (props.inCta ? '10px 10px 0 0' : '10px 10px 0 0')};
  width: 100%;

  @media (min-width: ${minTablet}) {
    margin: ${(props) => (props.inCta ? '5px 10px 5px 0' : '10px 10px 0 0')};
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    max-width: ${(props) => (props.inCta ? '70%' : '100%')};
  }

  @media (min-width: ${max1025}) {
    max-width: ${(props) => (props.inCta ? '80%' : '100%')};
  }

  @media (min-width: ${min1280}) {
    max-width: 640px;
  }
`;

export const Label = styled.label`
  color: ${colors.inHome.darkGrey};
`;

export const Validation = styled.div`
  color: ${colors.inHome.error};
  line-height: (26px/16px);
`;

export const PrivacyStatement = styled.div`
  ${responsiveFont({ sizes: '13, 14' })}

  margin-top: 20px;
  letter-spacing: -0.02em;

  color: ${(props) => (props.inCta && !props.roundedTexts ? colors.white : colors.inHome.darkGrey)};

  @media (min-width: ${minTablet}) {
    margin-bottom: 25px;
  }
`;

export const InlineLink = styled(Link)`
  ${responsiveFont({ sizes: '13, 14' })}
  color: inherit;
  text-decoration: underline;
`;

export const ThankYouContainer = styled.div`
  span {
    ${(props) => (props.inCta ? responsiveFont({ sizes: '18,20' }) : responsiveFont({ sizes: '16,19' }))};
    color: ${(props) => (props.inCta && !props.roundedTexts ? colors.white : colors.walmart.blue)};
    display: ${(props) => (!props.inCta ? 'flex' : 'block')};
    margin-top: ${(props) => (props.inCta ? '10px' : '0')};

    svg {
      width: 24px;
      margin-right: 10px;
    }

    @media (min-width: ${minTablet}) {
      margin-top: ${(props) => (props.inCta ? '30px' : '0')};
    }
  }
`;

export const ThankYouH2 = styled.h2`
  ${(props) => (props.inCta ? responsiveFont({ sizes: '32, 52' }) : responsiveFont({ sizes: '24, 32' }))};
  line-height: 32px;
  font-weight: 700;
  letter-spacing: -0.67px;
  color: ${(props) => (props.inCta && !props.roundedTexts ? colors.white : colors.walmart.blue)};
  margin-bottom: 8px;

  display: flex;
  flex-direction: column;

  @media (min-width: ${minTablet}) {
    flex-direction: ${(props) => (props.inCta ? 'row' : 'column')};
  }

  svg {
    width: 28px;
    height: auto;
    align-self: center;
    margin-bottom: 15px;

    @media (min-width: ${minTablet}) {
      margin-bottom: ${(props) => (props.inCta ? '0' : '15px')};
      margin-right: ${(props) => (props.inCta ? '10px' : '0')};
    }
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${minTablet}) {
    max-width: 785px;
  }

  @media (min-width: ${min1440}) {
    max-width: 950px;
  }
`;

export const Disclaimer = styled.div`
  ${responsiveFont({ sizes: '12,14' })};

  display: flex;
  flex-direction: column;

  margin-top: 5px;

  line-height: ${19 / 14};
  color: ${colors.inHome.darkGrey};
`;

export const ZipCodeContainer = styled.div`
  margin: 11px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  input {
    width: 80%;
    margin-right: 14px;
  }

  @media (min-width: ${min1440}) {
    margin: 0;
    input {
      width: 30%;
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 140px;
      border-bottom-right-radius: 140px;
    }
  }
`;

export const IncludeZipcodeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${min1440}) {
    flex-direction: row;
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;
